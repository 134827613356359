/**
 * @generated SignedSource<<5b703ef78a1d4e12bc45b195e5737994>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CopyRecipientType = "COPY_RECIPIENT_TYPE_BCC" | "COPY_RECIPIENT_TYPE_CC" | "%future added value";
export type EmailSettingsPageQueriesSettingsUIQuery$variables = {
  id: string;
};
export type EmailSettingsPageQueriesSettingsUIQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"EmailSettingsPageQueriesSettingsUIFragment">;
  } | null;
};
export type EmailSettingsPageQueriesSettingsUIQuery$rawResponse = {
  readonly node: {
    readonly __typename: "Company";
    readonly emailAccount: {
      readonly fromAddress: string | null;
      readonly id: string;
      readonly isReadyToSend: boolean | null;
      readonly sendingDomain: string | null;
    } | null;
    readonly emailSettings: {
      readonly emailCopyRecipients: ReadonlyArray<{
        readonly emailAddress: string;
        readonly recipientType: CopyRecipientType;
      }>;
      readonly enableCopyRecipientsForCampaigns: boolean | null;
      readonly enableCopyRecipientsForJourneys: boolean | null;
      readonly linkSubdomain: string;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly sendingDomains: ReadonlyArray<{
      readonly dnsRecords: {
        readonly dkim1: {
          readonly aliasDomainName: string;
          readonly sendgridDomainName: string;
          readonly valid: boolean;
        };
        readonly dkim2: {
          readonly aliasDomainName: string;
          readonly sendgridDomainName: string;
          readonly valid: boolean;
        };
        readonly mailCname: {
          readonly aliasDomainName: string;
          readonly sendgridDomainName: string;
          readonly valid: boolean;
        };
      };
      readonly domain: string;
      readonly domainId: number;
      readonly lastValidationAttempt: SerializedDateTime | null;
      readonly valid: boolean;
    }> | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type EmailSettingsPageQueriesSettingsUIQuery = {
  rawResponse: EmailSettingsPageQueriesSettingsUIQuery$rawResponse;
  response: EmailSettingsPageQueriesSettingsUIQuery$data;
  variables: EmailSettingsPageQueriesSettingsUIQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valid",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "aliasDomainName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sendgridDomainName",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailSettingsPageQueriesSettingsUIQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EmailSettingsPageQueriesSettingsUIFragment"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailSettingsPageQueriesSettingsUIQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailAccount",
                "kind": "LinkedField",
                "name": "emailAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fromAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isReadyToSend",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sendingDomain",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailSettings",
                "kind": "LinkedField",
                "name": "emailSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmailCopyRecipient",
                    "kind": "LinkedField",
                    "name": "emailCopyRecipients",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emailAddress",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recipientType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "linkSubdomain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enableCopyRecipientsForCampaigns",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enableCopyRecipientsForJourneys",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SendingDomain",
                "kind": "LinkedField",
                "name": "sendingDomains",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastValidationAttempt",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DnsRecords",
                    "kind": "LinkedField",
                    "name": "dnsRecords",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DnsRecord",
                        "kind": "LinkedField",
                        "name": "dkim1",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DnsRecord",
                        "kind": "LinkedField",
                        "name": "dkim2",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DnsRecord",
                        "kind": "LinkedField",
                        "name": "mailCname",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a76c06dc2fc107adba9f08ec33d8c8d1",
    "id": null,
    "metadata": {},
    "name": "EmailSettingsPageQueriesSettingsUIQuery",
    "operationKind": "query",
    "text": "query EmailSettingsPageQueriesSettingsUIQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Company {\n      ...EmailSettingsPageQueriesSettingsUIFragment\n    }\n    id\n  }\n}\n\nfragment EmailSettingsPageQueriesSettingsUIFragment on Company {\n  id\n  name\n  emailAccount {\n    fromAddress\n    isReadyToSend\n    sendingDomain\n    id\n  }\n  ...useCcBccEmailsMessageCreationCoreFragment_Company\n  emailSettings {\n    linkSubdomain\n    enableCopyRecipientsForCampaigns\n    enableCopyRecipientsForJourneys\n  }\n  sendingDomains {\n    domainId\n    domain\n    lastValidationAttempt\n    valid\n    dnsRecords {\n      dkim1 {\n        aliasDomainName\n        sendgridDomainName\n        valid\n      }\n      dkim2 {\n        aliasDomainName\n        sendgridDomainName\n        valid\n      }\n      mailCname {\n        aliasDomainName\n        sendgridDomainName\n        valid\n      }\n    }\n  }\n}\n\nfragment useCcBccEmailsMessageCreationCoreFragment_Company on Company {\n  emailSettings {\n    emailCopyRecipients {\n      emailAddress\n      recipientType\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a41d2325b28f528f3cfe4a2207255b45";

export default node;
